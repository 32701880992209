import React from 'react';
// @<mui
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const StyledAvatarSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '2em',
  width: '2em',
  aspectRatio: 1
}));
const StyledTextSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: '1rem',
  width: '100%'
}));

const SidebarLoading = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
    return (
      <Box p={1} key={item}>
        <Stack direction="row" sx={{ width: '100%', gap: 1 }}>
          <StyledAvatarSkeleton variant="circular" sx={{ bgcolor: 'dark.light' }}>
            <Avatar />
          </StyledAvatarSkeleton>
          <StyledTextSkeleton variant="text" sx={{ bgcolor: 'dark.light' }} />
        </Stack>
      </Box>
    );
  });
};

export default SidebarLoading;
