import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
// @mui
import { Box, Button, Drawer, Stack } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import { useAuth } from 'src/hooks/useAuth';
// components
import TreeItem from '../../components/TreeItem';
import EduPortalLogo from 'src/components/eduPortalLogo';

// icons
import LogoutIcon from '@mui/icons-material/Logout';
import AppsOutageOutlinedIcon from '@mui/icons-material/AppsOutageOutlined';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// custom
import { NAV_WIDTH } from '../configs';
import SidebarLoading from 'src/layouts/components/sidebarLoading';
import { icons } from 'src/navigation';
import { usePrivileges } from 'src/hooks/usePrivileges';
import useUser from 'src/hooks/useUser';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { useSessionKey } from 'src/hooks/useSessionKey';

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
};
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.dark.main,
  borderRadius: '9999px',
  fontWeight: 700,
  boxShadow: 0,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.dark.main
  }
}));

const renderTreeNode = (nodes) => {
  if (!nodes) return null;

  return nodes.map((node) => (
    <TreeItem
      key={node.id}
      id={node.id}
      label={node.label}
      route={node.route}
      pageName={node.page_name}
      icon={
        icons[node.label] ?? ((isActive) => <AppsOutageOutlinedIcon fontSize="small" sx={{ color: isActive ? 'grey.50' : 'grey.500' }} />)
      }
    >
      {renderTreeNode(node.children)}
    </TreeItem>
  ));
};

function Nav({ openNav, onCloseNav }) {
  const { privileges, isLoading, error } = usePrivileges();
  const { USERDATA: user } = useUser();

  const { pathname } = useLocation();
  const auth = useAuth();
  const key = useSessionKey();

  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function giveComponent() {
    if (isLoading) {
      return <SidebarLoading />;
    }
    if (error) {
      toast.error(error);
      return <SidebarLoading />;
    }
    return renderTreeNode(privileges.PRIVILEGES);
  }

  const renderContent = (
    <Box
      sx={{
        minHeight: '100%',
        overflow: 'auto',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          width: '100%',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <EduPortalLogo mode="light" size="small" />
        <Typography
          variant="body2"
          color="grey.50"
          fontWeight={700}
          sx={{
            mt: 1,
            width: 180,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {user.BranchName}
        </Typography>
        <Typography variant="body2" color="grey.200">
          ( {user.sessionYear} )
        </Typography>
      </Box>
      <Divider />
      <Link
        href={externalLinkGenerator('/software_demo_videos.php', {
          key
        })}
        underline="none"
      >
        <Stack direction="row" width="100%" alignItems="center" p={1} pl={2}>
          <PlayCircleIcon sx={{ mr: 1 }} />
          <Typography>Guideline Videos</Typography>
        </Stack>
      </Link>
      <MenuList
        sx={{
          minHeight: 'calc(100% - 120px)'
        }}
      >
        {giveComponent()}
      </MenuList>
      <Stack sx={{ px: 2 }}>
        <StyledButton fullWidth variant="contained" endIcon={<LogoutIcon />} onClick={auth.logout}>
          LOGOUT
        </StyledButton>
      </Stack>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              backgroundColor: 'dark.main',
              borderRight: 0
            }
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH, backgroundColor: 'dark.main' }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

export default Nav;
