// import { handleError } from 'src/Utils/errorHandling';
import { types } from 'src/redux/types';
import * as Services from 'src/services';

// function combineObjectValues(obj) {
//   let str = "";
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       const value = obj[key];

//       if (value != null) {
//         if (typeof value === "object") {
//           str += combineObjectValues(value);
//         } else if (typeof value === "string") {
//           str += value.toLowerCase();
//         }
//       }
//     }
//   }
//   return str;
// }
/**
 * Flatten a nested object and concatenate all its values into a single string
 * @param {Object} obj - The input object
 * @returns {string} - The concatenated string
 */
const flattenObjectToString = (obj) => {
  let result = '';

  const processValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      // If the value is an object, recursively process it
      result += flattenObjectToString(value);
    } else {
      // If the value is not an object, concatenate it to the result string
      result += String(value);
    }
  };

  // Iterate through each key-value pair in the object
  Object.values(obj).forEach((value) => {
    // Process the value
    processValue(value);
  });

  return result;
};
export const GET_STUDENTS_API_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    let response = await Services.GET_STUDENTS_API(additionalOptions);
    const students = response.data?.result.map((student) => {
      const queriableString = flattenObjectToString(student);
      return { ...student, queriableString };
    });
    dispatch({ type: types.STUEDENTS.FETCH_SUCCESS, payload: students });
    dispatch({ type: types.STUEDENTS.STORE_CUTOFF_TIME, payload: response.data.cutOffTime });
    return students;
  } catch (error) {
    throw new Error(error);
  }
};

export const GET_SORTED_STUDENT_API_HANDLER = (additionalOptions) => async (dispatch) => {
  try {
    let response = await Services.GET_STUDENTS_API(additionalOptions);
    dispatch({ type: types.STUEDENTS.STORE_SORTED_STUDENTS, payload: response.data.result });
    if (response?.data?.cutOffTime) {
      dispatch({ type: types.STUEDENTS.STORE_CUTOFF_TIME, payload: response?.data?.cutOffTime });
    }
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
