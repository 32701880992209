import React, { memo } from 'react';

// icons
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// custom import
import { ActiveIconBox, RoundLowerBox, RoundeUpperBox, StyledLabel, StyledStack } from '../styles';

function ActiveNavLink({ title, icon, beforeBG, afterBG }) {
  return (
    <StyledStack
      direction="row"
      alignItems="center"
      sx={{
        '&:before': {
          borderRightColor: beforeBG
        },
        '&:after': {
          borderRightColor: afterBG
        }
      }}
    >
      <RoundeUpperBox />

      <RoundLowerBox />

      <ActiveIconBox>
        {icon ? (
          icon(true)
        ) : (
          <KeyboardDoubleArrowRightIcon
            fontSize="small"
            sx={{
              color: 'grey.50'
            }}
          />
        )}
      </ActiveIconBox>
      <StyledLabel className="navText" color="primary.main">
        {title}
      </StyledLabel>
    </StyledStack>
  );
}

export default memo(ActiveNavLink);
