import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

// custom imports
import { authConfig } from 'src/constants/configs/auth';
import { STORE_USER_HANDLER } from 'src/redux/actions/loginActions/actions';
import { USERDATA } from 'src/constants/configs';

const useUser = () => {
  const userData = useSelector((state) => state.USER.USERDATA);
  const dispatch = useDispatch();
  if (isEmptyObject(userData)) {
    const token = localStorage.getItem(authConfig.storageTokenKeyName);
    const decodedData = jwtDecode(token);

    const obj = {};
    obj[USERDATA] = decodedData;

    dispatch(STORE_USER_HANDLER(obj));
    return obj;
  } else {
    return userData;
  }
};

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
export default useUser;
