import { useContext } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { PrivilegesContext } from 'src/context/PrivilegesContext';
// import { GET_USER_PRIVILEGES_HANDLER } from 'src/redux/actions/loginActions/actions';

// export const usePrivileges = () => {
// 	const [isLoading, setIsLoading] = useState(true);
// 	const [error, setError] = useState(null);
// 	const privileges = useSelector((state) => ({
// 		PRIVILEGES: state.USER.USER_PRIVILEGES,
// 		FLAT_PRIVILEGES: state.USER.FLAT_PRIVILEGES,
// 	}));
// 	// Hooks
// 	const dispatch = useDispatch();
// 	useEffect(() => {
// 		if (!privileges.PRIVILEGES) {
// 			setIsLoading(true);
// 			dispatch(GET_USER_PRIVILEGES_HANDLER())
// 				.then(() => setIsLoading(false))
// 				.catch((error) => {
// 					setIsLoading(false);
// 					setError(error.message);
// 				});
// 		} else {
// 			setIsLoading(false);
// 		}
// 	// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, []);

// 	return { isLoading, privileges, error };
// };

export const usePrivileges = () => useContext(PrivilegesContext);
