import React, { memo } from 'react';

// icons
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// custom imports
import { NonActiveStyledIconBox, NonActiveStyledStack, StyledLabel } from '../styles';

function NonActiveNavlink({ icon, title }) {
  return (
    <NonActiveStyledStack
      direction="row"
      alignItems="center"
      sx={{
        '&:hover .navText,': {
          color: 'grey.50',
          marginLeft: '5px'
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'grey.50'
        }
      }}
    >
      <NonActiveStyledIconBox>
        {icon ? (
          icon(false)
        ) : (
          <KeyboardDoubleArrowRightIcon
            fontSize="small"
            sx={{
              color: 'grey.500'
            }}
          />
        )}
      </NonActiveStyledIconBox>

      <StyledLabel className="navText" color="grey.500">
        {title}
      </StyledLabel>
    </NonActiveStyledStack>
  );
}

export default memo(NonActiveNavlink);
