import { types } from 'src/redux/types';

const initialState = {
  FEE_HEADS: []
};

export const feeHeadsReducer = (state = initialState.FEE_HEADS, action) => {
  if (types.FEE_HEADS.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.FEE_HEADS.FETCH_SUCCESS === action.type) {
    return action.payload;
  }
  if (types.FEE_HEADS.FETCH_FAILURE === action.type) {
    return state;
  }
  return state;
};
