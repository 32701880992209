import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// ** mui imports
import { useTheme } from '@mui/material';
import ThemeProvider from 'src/@core/theme';
import RouterComponent from 'src/routes';
import { AuthProvider } from './context/AuthContext';
import PrivilegesProvider from 'src/context/PrivilegesContext';
import { LayoutProvider } from './context/LayoutContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  const muiTheme = useTheme();
  const toastOptions = {
    success: {
      style: {
        background: muiTheme.palette.success.main,
        color: muiTheme.palette.common.white
      }
    },
    error: {
      style: {
        background: muiTheme.palette.error.main,
        color: muiTheme.palette.common.white
      }
    },
    warning: {
      style: {
        background: muiTheme.palette.warning.main,
        color: muiTheme.palette.common.white
      }
    },
    info: {
      style: {
        background: muiTheme.palette.info.main,
        color: muiTheme.palette.common.white
      }
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutProvider>
        <PrivilegesProvider>
          <AuthProvider>
            <ThemeProvider>
              <Toaster position="top-right" reverseOrder={true} toastOptions={toastOptions} />
              <RouterComponent />
            </ThemeProvider>
          </AuthProvider>
        </PrivilegesProvider>
      </LayoutProvider>
    </QueryClientProvider>
  );
}

export default App;
