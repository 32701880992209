const { V1Url, V1Path } = require('src/constants/configs');

export function externalLinkGenerator(pagePath, paramsObj) {
  // segments for the query param
  const baseURL = V1Url;
  const path = `${V1Path}${pagePath}`;
  const params = new URLSearchParams({ ...paramsObj });
  // combining the url
  const url = new URL(path, baseURL);
  url.search = params.toString();
  return url;
}
