import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loadable from 'src/components/loadable';
import Layout from 'src/layouts/dashboard';

// route types
import PrivateRoutes from './privateRoute';
import SocketRoutes from './socketRoutes';
import AttendanceRoute from './attendanceRoute';
import * as routesConsts from 'src/constants/configs/pagesRoutesText';
import FeesRoute from './feeRoute';

// Pages
// import FeeRecievable from 'src/pages/Fee/Reports/FeeRecievableReport';
const Dashboard = Loadable(lazy(() => import('src/pages/Dashboard')));
const NotFoundPage = Loadable(lazy(() => import('src/pages/404')));
const Login = Loadable(lazy(() => import('src/pages/Login')));
const Attendence = Loadable(lazy(() => import('src/pages/Attendance')));
const MarkAttendance = Loadable(lazy(() => import('src/pages/Attendance/MarkAttendance')));
const ErrorPage = Loadable(lazy(() => import('src/pages/ErrorPage')));
const BlankAttendanceSheet = Loadable(lazy(() => import('src/pages/Attendance/BlankAttendanceSheet')));
const EditAttendance = Loadable(lazy(() => import('src/pages/Attendance/EditAttendance')));
const Unauthorized = Loadable(lazy(() => import('src/pages/Unauthorized')));
const SMSAttendance = Loadable(lazy(() => import('src/pages/Attendance/SMSAttendance')));
const ViewChallan = Loadable(lazy(() => import('src/pages/Fee/ViewChallan')));
const GenerateChallan = Loadable(lazy(() => import('src/pages/Fee/GenerateChallan')));
const Voucher = Loadable(lazy(() => import('src/pages/Fee/Voucher')));
const FeeSettings = Loadable(lazy(() => import('src/pages/Fee/FeeSettings')));
const ClasswiseFeeSettings = Loadable(lazy(() => import('src/pages/Fee/ClasswiseFeeSettings')));
const FeeReportsAndSettings = Loadable(lazy(() => import('src/pages/Fee/FeeReportsAndSettings')));
const HeadsSettings = Loadable(lazy(() => import('src/pages/Fee/HeadsSettings')));
const HeadwiseReport = Loadable(lazy(() => import('src/pages/Fee/Reports/HeadwiseReport')));
const FeeRecievableReport = Loadable(lazy(() => import('src/pages/Fee/Reports/feeRecievableReport/index')));
const UserProfitLossReport = Loadable(lazy(() => import('src/pages/Fee/Reports/UserProfitLossReport')));
const StudentInstallment = Loadable(lazy(() => import('src/pages/Fee/Reports/StudentInstallmentReport')));
const MultiMonthsFeeReport = Loadable(lazy(() => import('src/pages/Fee/Reports/MultiMonthsFeeReport')));
const SearchStudentWithFee = Loadable(lazy(() => import('src/pages/Fee/Reports/SearchStudentWithFee')));
const TotalDatewiseFeeCollection = Loadable(lazy(() => import('src/pages/Fee/Reports/TotalDatewiseFeeCollectionReport')));
const DatewiseFeeCollection = Loadable(lazy(() => import('src/pages/Fee/Reports/DatewiseFeeCollection')));
const ArrearFeeReport = Loadable(lazy(() => import('src/pages/Fee/Reports/ArrearFeeReport')));
const UserwiseFeeCollection = Loadable(lazy(() => import('src/pages/Fee/Reports/UserWiseFeeReport')));
const AttendanceReports = Loadable(lazy(() => import('src/pages/Attendance/AttendanceReports')));
const DayAttendanceSummary = Loadable(lazy(() => import('src/pages/Attendance/Reports/dayAttendanceSummary')));
const DatewiseAttendanceReport = Loadable(lazy(() => import('src/pages/Attendance/Reports/datewiseAttendanceReport')));
const InstituteAttendanceSummary = Loadable(lazy(() => import('src/pages/Attendance/Reports/instituteAttendanceSummary')));
const FamilyFeeAndReporting = Loadable(lazy(() => import('src/pages/Fee/FamilyFeeAndReporting')));
const FamilyReport = Loadable(lazy(() => import('src/pages/Fee/Reports/familiesReport')));
const FilledAttendanceSheet = Loadable(lazy(() => import('src/pages/Attendance/Reports/filledAttendanceSheet')));
const MostAbsentees = Loadable(lazy(() => import('src/pages/Attendance/Reports/mostAbsentees')));
const DailyAttendanceReport = Loadable(lazy(() => import('src/pages/Attendance/Reports/dailyAttendanceReport')));
const NewVoucher = Loadable(lazy(() => import('src/pages/Fee/Voucher/index')));

const RouterComponent = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<FeesRoute />}>
          <Route element={<Layout />}>
            <Route path={routesConsts.index} element={<SocketRoutes />}>
              <Route index element={<Dashboard />} />
            </Route>

            {/* FEE COLLECTION */}
            <Route path={routesConsts.feesIndex}>
              <Route path={routesConsts.viewChallan} element={<ViewChallan />} />
              <Route path={routesConsts.generatChallan} element={<GenerateChallan />} />
              <Route path={routesConsts.headsSettings} element={<HeadsSettings />} />
              <Route path={routesConsts.feeReportsAndSettings} element={<FeeReportsAndSettings />} />
              <Route path={routesConsts.feeSettigs} element={<FeeSettings />} />
              <Route path={routesConsts.classwiseFeeUpdate} element={<ClasswiseFeeSettings />} />
              <Route path={routesConsts.feeRecievableReport} element={<FeeRecievableReport />} />
              <Route path={routesConsts.headwiseReport} element={<HeadwiseReport />} />
              <Route path={routesConsts.userProfitLostReport} element={<UserProfitLossReport />} />
              <Route path={routesConsts.installmentStudents} element={<StudentInstallment />} />
              <Route path={routesConsts.multiMonthsFeeReport} element={<MultiMonthsFeeReport />} />
              <Route path={routesConsts.studentSearchWithFee} element={<SearchStudentWithFee />} />
              <Route path={routesConsts.totalDatewiseFeeReport} element={<TotalDatewiseFeeCollection />} />
              <Route path={routesConsts.datewiseFeeCollectionReport} element={<DatewiseFeeCollection />} />
              <Route path={routesConsts.arrearsReport} element={<ArrearFeeReport />} />
              <Route path={routesConsts.userwiseFeeReport} element={<UserwiseFeeCollection />} />
              <Route path={routesConsts.familyFeeAndReporting} element={<FamilyFeeAndReporting />} />
              <Route path={routesConsts.familiesFeeReport} element={<FamilyReport />} />
            </Route>

            {/* ATTENDANCE */}
            <Route path={routesConsts.attendanceIndex} element={<AttendanceRoute />}>
              <Route path={routesConsts.viewAttendance} element={<Attendence />} />
              <Route path={routesConsts.markAttendance} element={<MarkAttendance />} />
              <Route path={routesConsts.editAttendance} element={<EditAttendance />} />
              <Route path={routesConsts.smsAttendance} element={<SMSAttendance />} />
              <Route path={routesConsts.attendanceReports} element={<AttendanceReports />} />
              <Route path={routesConsts.dayAttendanceSummary} element={<DayAttendanceSummary />} />
              <Route path={routesConsts.datewiseAttendanceReport} element={<DatewiseAttendanceReport />} />
              <Route path={routesConsts.filledAttendanceSheet} element={<FilledAttendanceSheet />} />
            </Route>
            <Route path={routesConsts.accessDenied} element={<Unauthorized />} />
          </Route>
              <Route path={`${routesConsts.attendanceIndex}/${routesConsts.dailyAttendanceReport}`} element={<DailyAttendanceReport />} />
              <Route path={`${routesConsts.attendanceIndex}/${routesConsts.mostAbsentees}`}element={<MostAbsentees />} />
          <Route path={`${routesConsts.attendanceIndex}/${routesConsts.instituteAttendanceSummary}`} element={<InstituteAttendanceSummary />} />
          <Route path={routesConsts.blankAttendanceSheet} element={<BlankAttendanceSheet />} />
          <Route path={`${routesConsts.feesIndex}/${routesConsts.voucher}`} element={<NewVoucher />} />
          {/* <Route path={`${routesConsts.feesIndex}/${routesConsts.voucher}`} element={<Voucher />} /> */}
        </Route>
      </Route>
      <Route path={routesConsts.login} element={<Login />} />
      <Route path={routesConsts.error} element={<ErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterComponent;
