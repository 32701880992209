import { flatPrivileges } from 'src/Utils/flatternPrivileges';
import {
  accessDenied,
  attendanceIndex,
  blankAttendanceSheet,
  classwiseFeeUpdate,
  editAttendance,
  feeSettigs,
  feesIndex,
  headsSettings,
  voucher
} from 'src/constants/configs/pagesRoutesText';
import { types } from 'src/redux/types';

const initialState = {
  USER: { USERDATA: {} }
};

export const loginReducer = (state = initialState.USER, action) => {
  if (types.LOGIN.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.LOGIN.FETCH_SUCCESS === action.type) {
    return { ...state, USERDATA: action.payload };
  }
  if (types.LOGIN.FETCH_FAILURE === action.type) {
    return state;
  }
  if (types.LOGIN.STORE_USER === action.type) {
    return { ...state, USERDATA: action.payload };
  }
  if (types.USERPRIVILEGES.STORE_USER_PRIVILEGES === action.type) {
    const privileges = action?.payload?.result;
    const FLAT_PRIVILEGES = flatPrivileges(privileges);
    FLAT_PRIVILEGES.push(accessDenied);
    if (FLAT_PRIVILEGES.includes(attendanceIndex)) {
      FLAT_PRIVILEGES.push(`${blankAttendanceSheet}`, `${attendanceIndex}/${editAttendance}`);
    }
    if (FLAT_PRIVILEGES.includes(feesIndex)) {
      FLAT_PRIVILEGES.push(
        `${feesIndex}/${voucher}`,
        `${feesIndex}/${headsSettings}`,
        `${feesIndex}/${feeSettigs}``${feesIndex}/${classwiseFeeUpdate}`
      );
    }
    return {
      ...state,
      USER_PRIVILEGES: privileges,
      FLAT_PRIVILEGES,
      USER_PERMISSIONS: action?.payload?.userPermissions
    };
  }
  return state;
};
