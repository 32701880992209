import React, { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

// @mui
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
// custom
import { authConfig } from 'src/constants/configs/auth';
import { usePrivileges } from 'src/hooks/usePrivileges';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { useSessionKey } from 'src/hooks/useSessionKey';
import { login, error as errorRouteConst } from 'src/constants/configs/pagesRoutesText';
import { useAuth } from 'src/hooks/useAuth';

const PrivateRoutes = () => {
  const location = useLocation();
  const key = useSessionKey();
  const { isInitialized } = useAuth();
  const JWtoken = localStorage.getItem(authConfig.storageTokenKeyName);
  const { privileges, isLoading, error, refetch } = usePrivileges();

  useEffect(() => {
    if (isInitialized) {
      refetch();
    }
  }, [isInitialized, refetch]);
  const isAuthorized = () => {
    if (privileges && !privileges.FLAT_PRIVILEGES) return false;
    return privileges.FLAT_PRIVILEGES.includes(decodeURIComponent(location.pathname));
  };

  function isNotEmpty(myVariable) {
    return myVariable !== undefined && myVariable !== null && myVariable !== '';
  }
  if (!isNotEmpty(JWtoken)) {
    return <Navigate to="/login" />;
  }
  if (isLoading || !privileges || !privileges.FLAT_PRIVILEGES) {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Stack>
    );
  }
  if (error?.response?.status === 401) {
    return <Navigate to={login} />;
  }
  if (error) {
    return <Navigate to={errorRouteConst} />;
  }
  if (isNotEmpty(JWtoken)) {
    if (isAuthorized()) {
      return <Outlet />;
    }
    // if user don't have main dashboard access
    if (
      privileges &&
      privileges?.FLAT_PRIVILEGES?.includes('/dashboard') &&
      !privileges?.FLAT_PRIVILEGES?.includes('/') &&
      privileges?.FLAT_PRIVILEGES?.includes('/dashboard/staff-dashboard')
    ) {
      window.open(externalLinkGenerator(`/basic_dashboard.php`, { key }), '_self');
      return;
    }
    // if user don't have over all dashboard access
    if (privileges && privileges?.PRIVILEGES[0] && !privileges?.FLAT_PRIVILEGES?.includes('/dashboard')) {
      window.open(externalLinkGenerator(`/${privileges.PRIVILEGES[0].children[0].page_name}`, { key }), '_self');
      return;
    }
    return <Navigate to="/access-denied" />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoutes;
