import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { SOCKET_URL } from 'src/constants/configs';
import { authConfig } from 'src/constants/configs/auth';

const SocketContext = React.createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const environment = process.env.REACT_APP_ENVIROMENT;

  const [socket, setSocket] = useState();

  useEffect(() => {
    const getKey = () => {
      if (environment === 'PRODUCTION' || environment === "DEVELOP") {
        return 'query';
      }
      return 'extraHeaders';
    };

    const accessToken = window.localStorage.getItem(authConfig.storageTokenKeyName);
    const newSocket = io(SOCKET_URL, {
      [getKey()]: {
        access_token: accessToken
      }
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, [environment]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
}
