import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box, Stack } from '@mui/material';

export const StyledLabel = styled(Typography)(() => ({
  // width: '160px',
  // overflow: 'hidden',
  whiteSpace: 'normal',
  // textOverflow: 'ellipsis',

  transition: 'margin-left .45s ease'
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  padding: '6px',
  paddingLeft: theme.spacing(2),
  color: theme.palette.grey[500],
  '&:hover': {
    color: theme.palette.grey[50]
  }
}));

export const StyledIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  borderRadius: 9999,
  padding: theme.spacing(0.5),
  height: '30px',
  width: '30px'
}));

export const ActiveIconBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  padding: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px'
}));

export const RoundeUpperBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  width: '20px',
  right: 0,
  height: '65px',
  backgroundColor: theme.palette.background.default
}));

export const RoundLowerBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '35px',
  width: '20px',
  height: '30px',
  right: 0,
  backgroundColor: theme.palette.background.default
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  textAlign: 'center',
  zIndex: 1,
  borderRadius: '30px 0 0 30px',
  border: '0 solid transparent',
  borderRight: 0,
  margin: '0px auto 0px 0',
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  fontSize: '14px',
  fontWeight: 400,
  transition: 'all 300ms ease-in',

  '&:hover .navText,': {
    // color: !isActive && 'grey.50',
    marginLeft: '5px'
  },
  // '&:hover .MuiSvgIcon-root': {
  // 	color: !isActive && 'grey.50',
  // },

  '&:before , &:after': {
    borderRight: '21px solid',
    content: '""',
    position: 'absolute',
    height: '30px',
    right: 0,
    bottom: 0,
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderBottom: 'transparent'
  },
  '&:before': {
    borderRadius: '0 0 48px 0',
    top: '-30px',
    zIndex: 9,
    backgroundColor: theme.palette.background.default
  },
  '&:after': {
    borderRadius: '0 48px 0 0',
    top: '43px',
    zIndex: 0,
    backgroundColor: theme.palette.background.default
  }
}));

export const NonActiveStyledIconBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: 'transparent',
  borderRadius: '50%',
  padding: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  width: '30px'
}));

export const NonActiveStyledStack = styled(Stack)(() => ({
  backgroundColor: '',
  position: 'relative',
  textAlign: 'center',
  zIndex: 1,
  borderRadius: '30px 0 0 30px',
  border: '0 solid transparent',
  borderRight: 0,
  margin: '0px auto 0px 0',
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  padding: '6px',
  fontSize: '14px',
  fontWeight: 400,
  transition: 'all 300ms ease-in'
}));
