import jwtDecode from 'jwt-decode';
import { handleError } from 'src/Utils/errorHandling';
import { USERDATA } from 'src/constants/configs';
import { types } from 'src/redux/types';
import * as Services from 'src/services';

export const LOGIN_API_HANDLER = (body) => async (dispatch) => {
  try {
    let response = await Services.Login_API(body);
    const obj = {};
    obj[USERDATA] = jwtDecode(response.data.access_token);

    dispatch({ type: types.LOGIN.FETCH_SUCCESS, payload: obj });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const STORE_USER_HANDLER = (data) => (dispatch) => {
  dispatch({ type: types.LOGIN.FETCH_SUCCESS, payload: data });
};

export const GET_USER_PRIVILEGES_HANDLER = () => async (dispatch) => {
  try {
    let response = await Services.GET_USER_PRIVILEGES();
    dispatch({
      type: types.USERPRIVILEGES.STORE_USER_PRIVILEGES,
      payload: response?.data
    });
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const SMS_COUNT_API_HANDLER = () => async (dispatch) => {
  try {
    let response = await Services.SMS_COUNT_API();
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const Notifications_API_HANDLER = () => async (dispatch) => {
  try {
    let response = await Services.Notifications_API();
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
