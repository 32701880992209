// ** React Imports
import { createContext, useContext, useState } from 'react';

const ReservedContext = createContext();

const ReservedProvider = ({ children }) => {
  // ** States
  const [preservedValue, setPreservedValue] = useState();

  const values = {
    preservedValue,
    setPreservedValue
  };

  return <ReservedContext.Provider value={values}>{children}</ReservedContext.Provider>;
};

export { ReservedContext, ReservedProvider };
export const useReserved = () => useContext(ReservedContext);
