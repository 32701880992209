import urls from './routApis';

const prefix = 'eduPortal-';

export const authConfig = {
  storageTokenKeyName: `${prefix}accessToken`,
  userPrivileges: `${prefix}userPrivileges`,
  flatPrivileges: `${prefix}flatPrivileges`,
  loginEndpoint: urls.LOGIN,
  registerEndpoint: '/jwt/register',
  sessionID: 'sessionID'
};

const environment = process.env.REACT_APP_ENVIROMENT;
export const getAuthToken = () => {
  const token = window.localStorage.getItem(authConfig.storageTokenKeyName);

  if (environment === 'PRODUCTION' || environment === 'DEVELOP') {
    return `Bearer ${token}`;
  }
  return token;
};

export const getKey = () => {
  if (environment === 'PRODUCTION' || environment === 'DEVELOP') {
    return 'Authorization';
  }
  return 'access_token';
};
