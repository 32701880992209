import React from 'react';
import { Outlet } from 'react-router-dom';
import { AttendanceProvider } from 'src/context/Attendance';
import { EditProvider } from 'src/features/attendance/context/EditContext';

const AttendanceRoute = () => {
  return (
    <EditProvider>
      <AttendanceProvider>
        <Outlet />
      </AttendanceProvider>
    </EditProvider>
  );
};

export default AttendanceRoute;
