import React from 'react';
import { Outlet } from 'react-router-dom';
import { ReservedProvider } from 'src/context';

const FeesRoute = () => {
  return (
    <ReservedProvider>
      <Outlet />
    </ReservedProvider>
  );
};

export default FeesRoute;
