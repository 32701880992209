// CardUpdatesContext.js
import React, { useContext, useState } from 'react';

const AttendanceContext = React.createContext();

export function useCardUpdates() {
  return useContext(AttendanceContext);
}

export function AttendanceProvider({ children }) {
  const [attendance, setAttendance] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  return <AttendanceContext.Provider value={{ attendance, setAttendance, isLoading, setIsLoading }}>{children}</AttendanceContext.Provider>;
}
