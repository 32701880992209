import { types } from 'src/redux/types';

const initialState = {
  FEE: []
};

export const feesReducer = (state = initialState.FEE, action) => {
  if (types.SOCIALPOSTS.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.SOCIALPOSTS.FETCH_SUCCESS === action.type) {
    return state;
  }
  if (types.SOCIALPOSTS.FETCH_FAILURE === action.type) {
    return state;
  }
  return state;
};
