import React, { createContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

// project imports
import urls from 'src/constants/configs/routApis';
import { fetchWrapper } from 'src/Utils/fetchWrapper';
import { flatPrivileges } from 'src/Utils/flatternPrivileges';
import { additionalPrivileges } from 'src/Utils/additionalPrivileges';
import { authConfig } from 'src/constants/configs/auth';

export const PrivilegesContext = createContext();

function PrivilegesProvider({ children }) {
  const [privileges, setPrivileges] = useState(null);

  const { isPending, error, refetch } = useQuery({
    queryKey: ['privileges'],
    queryFn: async () => {
      const response = await fetchWrapper('GET', urls.USER_PRIVILEGES);
      if (response?.response?.status === 401) {
        setPrivileges(response);
        return response;
      }
      const flatPrivilegesArray = flatPrivileges(response.data.result);
      const privileges = {
        PRIVILEGES: response.data.result,
        FLAT_PRIVILEGES: [...flatPrivilegesArray, ...additionalPrivileges(flatPrivilegesArray)],
        USER_PERMISSIONS: response.data?.userPermissions
      };
      setPrivileges(privileges);
      return privileges;
    },
    enabled: Boolean(window.localStorage.getItem(authConfig.storageTokenKeyName))
  });

  return <PrivilegesContext.Provider value={{ privileges, isLoading: isPending, error, refetch }}>{children}</PrivilegesContext.Provider>;
}

export default PrivilegesProvider;
