import React, { useState, Fragment } from 'react';
import moment from 'moment';
// @mui
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

// icons
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

// custom
import { externalLinkGenerator } from 'src/Utils/externalLink';
import useUser from 'src/hooks/useUser';
import { useSessionKey } from 'src/hooks/useSessionKey';
// import * as routeConsts from 'src/constants/configs/pagesRoutesText';

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.action.selected,
  borderRadius: 999999,
  paddingInline: '6px',
  width: '100%',
  border: `1px solid ${theme.palette.action.selectedOpacity}`
}));

export default function QuickLinksDropdown() {
  const { USERDATA: user } = useUser();
  const key = useSessionKey();
  const [open, setOpen] = useState(null);
  // const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleClick = (link) => {
    window.open(link, '_blank');
  };
  const currentMonth = moment().month() + 1;
  const quickLinks = [
    [
      {
        label: 'Class Wise Student Report',
        link: externalLinkGenerator('/students_reports.php', { key })
      }
    ],
    [
      {
        label: 'Mark Attendance',
        link: externalLinkGenerator('/mark_attendance_list.php', { key })
      },
      {
        label: 'Emp Attendance',
        link: externalLinkGenerator('/monthly_attendance.php', { key })
      }
    ],
    [
      {
        label: 'Old Students',
        link: externalLinkGenerator('/old_student_profile.php', { key })
      }
    ],
    [
      {
        label: 'View Challan',
        link: externalLinkGenerator('/view_challan.php', { key })
      },
      {
        label: 'Un-Created Challan List',
        link: externalLinkGenerator('/students_uncreated_challan.php', {
          session: user.sessionId,
          month: currentMonth,
          submit: ''
        })
      }
    ],
    [
      {
        label: 'Families',
        link: externalLinkGenerator('/families.php', { key })
      }
    ],
    [
      {
        label: 'New SMS',
        link: externalLinkGenerator('/new_sms.php', { key })
      },
      {
        label: 'View Sent SMS:',
        link: externalLinkGenerator('/sent_message.php', { key })
      }
    ],
    [
      {
        label: 'Daily Closing',
        link: externalLinkGenerator('/print_daily_income_exp_report.php', {
          from: moment().format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
          submit: '',
          key
        })
      },
      {
        label: 'Monthly Closing',
        link: externalLinkGenerator('/print_profitloss_report.php', {
          month: '',
          year: '',
          key
        })
      },
      {
        label: 'Expenses',
        link: externalLinkGenerator('/add_expenses.php', { key })
      }
    ],
    [
      {
        label: 'Users Activities',
        link: externalLinkGenerator('/user_audit_report.php', { key })
      },
      {
        label: 'User Access',
        link: externalLinkGenerator('/user_access.php', { key })
      },
      {
        label: 'View Parents IDs',
        link: externalLinkGenerator('/parents_id.php', { key })
      }
    ]
  ];

  return (
    <Box sx={{ flex: '0 1', minWidth: '160px' }}>
      <StyledButton endIcon={<ArrowDropDownOutlinedIcon />} onClick={handleOpen}>
        Quick Links
      </StyledButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 2,
            mt: 1.5,
            ml: 0.75,
            width: 340
          }
        }}
      >
        {quickLinks.map((item, index) => {
          return (
            <Fragment key={index + '44nsda'}>
              {item.map((quickItem) => {
                return (
                  <MenuItem key={quickItem.label} value={quickItem.link} onClick={() => handleClick(quickItem.link)}>
                    <ListItemText>{quickItem.label}</ListItemText>
                    <ListItemIcon>
                      <ArrowOutwardIcon fontSize="10px" />
                    </ListItemIcon>
                  </MenuItem>
                );
              })}

              {index !== quickLinks.length - 1 && <Divider />}
            </Fragment>
          );
        })}
        <Divider />
        {/* <MenuItem
					disabled
					onClick={() =>
						navigate(`${routeConsts.feesIndex}/${routeConsts.feeSettings}`)
					}
				>
					<ListItemText>Fee Settings</ListItemText>
					<ListItemIcon>
						<ArrowOutwardIcon fontSize='10px' />
					</ListItemIcon>
				</MenuItem> */}
      </Popover>
    </Box>
  );
}
