import { types } from 'src/redux/types';

const initialState = {
  ATTENDANCE: []
};

export const attendanceReducer = (state = initialState.ATTENDANCE, action) => {
  if (types.ATTENDANCE.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.ATTENDANCE.FETCH_SUCCESS === action.type) {
    return action.payload;
  }
  if (types.ATTENDANCE.FETCH_FAILURE === action.type) {
    return state;
  }
  if (types.ATTENDANCE.STORE_ATTENDANCE === action.type) {
    return action.payload;
  }
  return state;
};
