// icons
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// custom
import { StyledIcon, StyledLabel, StyledLink } from '../styles';
import { Box, Stack } from '@mui/material';

function ExternalLink({ hyperLink, label }) {
  return (
    <Box
      sx={{
        '&:hover .navText': {
          marginLeft: '5px'
        }
      }}
    >
      <StyledLink underline="none" href={hyperLink} target="_blank">
        <Stack direction="row" alignItems="center">
          <StyledIcon>
            <KeyboardDoubleArrowRightIcon fontSize="small" />
          </StyledIcon>
          <StyledLabel className="navText">{label}</StyledLabel>
        </Stack>
      </StyledLink>
    </Box>
  );
}

export default ExternalLink;
