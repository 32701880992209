import { fetchWrapper } from '../Utils/fetchWrapper';
import urls from '../constants/configs/routApis';

//? Login
export const Login_API = (body) => fetchWrapper('POST', urls.LOGIN, body);

export const GET_USER_PRIVILEGES = () => fetchWrapper('GET', urls.USER_PRIVILEGES);

export const SMS_COUNT_API = () => fetchWrapper('GET', urls.SMS);

// ? Notification
export const Notifications_API = () => fetchWrapper('GET', urls.NOTIFICATION);

//? Students
export const GET_STUDENTS_API = (additionalOptions) => fetchWrapper('GET', urls.STUDENT, null, additionalOptions);

//? classes
export const GET_CLASSES_API = () => fetchWrapper('GET', urls.CLASSES);

//? Sessions
export const GET_SESSIONS_API = () => fetchWrapper('GET', urls.SESSIONS);

//? Attendance
export const GET_STUDENTS_ATTENDANCE_API = (additionalOptions) => fetchWrapper('GET', urls.ATTENDANCE, null, additionalOptions);

export const GET_MARKABLE_ATTENDANCE_STUDENTS = (additionalOptions) => fetchWrapper('GET', urls.MARKABLE_STUDENTS, null, additionalOptions);

export const GET_ABSENTEES_STUDENTS = (additionalOptions) => fetchWrapper('GET', urls.ABSENTEES_STUDENTS, null, additionalOptions);

export const POST_STUDENTS_ATTENDANCE = (body) => fetchWrapper('POST', urls.MARK_ATTENDANCE, body);

export const EDIT_STUDENTS_ATTENDANCE = (body) => fetchWrapper('PUT', urls.EDIT_ATTENDANCE, body);

export const DELETE_STUDENTS_ATTENDANCE = (body) => fetchWrapper('DELETE', urls.DELETE_ATTENDANCE, body);

export const MARK_HOLIDAY = (body) => fetchWrapper('POST', urls.MARK_HOLIDAY, body);

export const SEND_SMS = (body, additionalOptions) => fetchWrapper('POST', urls.SEND_SMS, body, additionalOptions);

//? Fee
export const CLASSWISE_CHALLAN = (additionalOptions) => fetchWrapper('GET', urls.CLASSWISE_CHALLAN, null, additionalOptions);

export const DELETE_CHALLAN = (body) => fetchWrapper('DELETE', urls.DELETE_CHALLAN, body);

export const CHALLAN_SMS = (additionalOptions) => fetchWrapper('GET', urls.CHALLAN_SMS, null, additionalOptions);

export const FEE_HEADS = (additionalOptions) => fetchWrapper('GET', urls.FEE_HEADS, null, additionalOptions);

export const POST_FEE_HEADS = (additionalOptions, body) => fetchWrapper('POST', urls.FEE_HEADS, body, additionalOptions);

export const STUDENTS_CHALLAN = (additionalOptions) => fetchWrapper('GET', urls.GET_STUDENTS_CHALLAN, null, additionalOptions);

export const GENERATE_CHALLAN = (body) => fetchWrapper('POST', urls.GENERATE_CHALLAN, body);

export const GET_SINGLE_CHALLAN = (additionalOptions) => fetchWrapper('GET', urls.GET_SINGLE_CHALLAN, null, additionalOptions);

export const EDIT_SINGLE_CHALLAN = (body) => fetchWrapper('PUT', urls.EDIT_SINGLE_CHALLAN, body);

export const GET_PAYMENT_DETAILS = (additionalOptions) => fetchWrapper('GET', urls.GET_PAYMENT_DETAILS, null, additionalOptions);

export const SAVE_PAYMENTS = (body) => fetchWrapper('PUT', urls.SAVE_PAYMETNS, body);

export const UNPAY_PAYMENT = (additionalOptions) => fetchWrapper('GET', urls.UNPAY_PAYMENT, null, additionalOptions);

export const Get_FAMILY_VOUCHER = (additionalOptions) => fetchWrapper('GET', urls.Family_Voucher, null, additionalOptions);

export const GET_FEE_SETTINGS = (additionalOptions) => fetchWrapper('GET', urls.FEE_SETTINGS, null, additionalOptions);

export const POST_FEE_SETTINGS = (body) => fetchWrapper('PUT', urls.FEE_SETTINGS, body);
