import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// project imports
import { GET_SORTED_STUDENT_API_HANDLER, GET_STUDENTS_API_HANDLER } from 'src/redux/actions/studentActions/index';
import useUser from './useUser';

export const useStudents = (sorted) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const {USERDATA:user} = useUser()
  const STUDENTS = useSelector((state) => state.STUDENTS);
  const cutOffTime = useSelector((state) => state.STUDENTS.cutOffTime);

  // hooks
  const dispatch = useDispatch();
  useEffect(() => {
    if (sorted) {
      if (Object.keys(STUDENTS.sortedStudentsData).length === 0) {
        setIsLoading(true);
        dispatch(GET_SORTED_STUDENT_API_HANDLER({ params: { sorted: true, sessionId : user.sessionId } }))
          .then(() => setIsLoading(false))
          .catch((error) => {
            setIsLoading(false);
            setError(error.message);
          });
      }
      setIsLoading(false);
      return;
    }

    if (STUDENTS.studentsData.length === 0) {
      setIsLoading(true);
      dispatch(GET_STUDENTS_API_HANDLER({ params: { status: 0 } }))
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
          setError(error.message);
        });
    }
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (sorted) {
    return {
      isLoading,
      sortedStudentsData: STUDENTS.sortedStudentsData,
      error,
      cutOffTime
    };
  }
  return { isLoading, studentsData: STUDENTS.studentsData, error, cutOffTime };
};
