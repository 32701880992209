import React from 'react';
import { Outlet } from 'react-router-dom';

// contexts
import { SocketProvider } from 'src/context/SocketContext';

const SocketRoutes = () => {
  return (
    <SocketProvider>
      <Outlet />
    </SocketProvider>
  );
};
export default SocketRoutes;
