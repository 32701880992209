import { types } from 'src/redux/types';

const initialState = {
  SESSIONS: []
};

export const sessionsReducer = (state = initialState.SESSIONS, action) => {
  if (types.SESSIONS.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.SESSIONS.FETCH_SUCCESS === action.type) {
    return action.payload;
  }
  if (types.SESSIONS.FETCH_FAILURE === action.type) {
    return state;
  }
  return state;
};
