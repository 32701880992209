import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

// icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// custom imports
import ActiveNavLink from './activeNavLink';
import NonActiveNavlink from './nonActiveNavlink';
import { internalRoutesList, sidebarCollapse } from 'src/navigation';
import ExternalLink from './externalLink';
import { StyledIcon, StyledLabel } from '../styles';
import { useSessionKey } from 'src/hooks/useSessionKey';
import { externalLinkGenerator } from 'src/Utils/externalLink';

const childrenBg = '#22222b';

const StyleModule = styled(Stack)(({ theme }) => ({
  margin: '0px auto 0px 0',
  width: 'inherit',
  alignItems: 'center',
  padding: '6px',
  fontSize: '14px',
  fontWeight: 400,
  '&:hover .navText,': {
    color: theme.palette.grey[50],
    marginLeft: '5px'
  },
  '&:hover .MuiSvgIcon-root': {
    color: theme.palette.grey[50]
  }
}));

const StyledDropDownIcon = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(2),
  transition: 'transform .45s ease'
}));

const StyledChildrenBox = styled(Box)(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(2),
  position: 'relative',
  backgroundColor: childrenBg
}));

const TreeItem = ({ children, label, id, icon, route }) => {
  const [openCollapse, setOpenCollapse] = useState('');
  const sessionKey = useSessionKey();
  const location = useLocation();

  const handleCollapse = (label) => {
    setOpenCollapse((prev) => {
      if (prev === label) return '';
      return label;
    });
  };
  useEffect(() => {
    setOpenCollapse(sidebarCollapse[location.pathname]);
  }, [location.pathname]);

  if (children.length > 0) {
    return (
      <MenuItem
        sx={{
          display: 'block',
          p: 0,
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        }}
      >
        <StyleModule component="div" direction="row" onClick={() => handleCollapse(label)}>
          <StyledIcon>{icon(false)}</StyledIcon>
          <StyledLabel className="navText" color={'grey.500'}>
            {label}
          </StyledLabel>
          <StyledDropDownIcon
            sx={{
              transform: openCollapse === label && 'rotate(90deg)'
            }}
          >
            <ChevronRightIcon fontSize="small" />
          </StyledDropDownIcon>
        </StyleModule>

        <Collapse in={openCollapse === label} timeout="auto" unmountOnExit>
          <StyledChildrenBox>
            {children.map((item, index) => {
              const { props } = item;
              return internalRoutesList.includes(props.route) ? (
                <NavLink to={props.route} key={props.id}>
                  {({ isActive }) =>
                    isActive ? (
                      <ActiveNavLink
                        title={props.label}
                        beforeBG={index !== 0 ? childrenBg : index === children.length - 1 ? childrenBg : 'dark.main'}
                        afterBG={index !== children.length - 1 ? childrenBg : 'dark.main'}
                      />
                    ) : (
                      <NonActiveNavlink title={props.label} />
                    )
                  }
                </NavLink>
              ) : (
                <ExternalLink
                  hyperLink={externalLinkGenerator(`/${props.pageName}`, {
                    key: sessionKey
                  })}
                  label={props.label}
                  key={props.id}
                />
              );
            })}
          </StyledChildrenBox>
        </Collapse>
      </MenuItem>
    );
  }
  return (
    <MenuItem
      sx={{
        p: 0,
        m: '6px 0',
        display: 'block',
        position: 'relative'
      }}
    >
      {internalRoutesList.includes(route) ? (
        <NavLink to={route}>
          {({ isActive }) =>
            isActive ? (
              <ActiveNavLink title={label} icon={icon} beforeBG="dark.main" afterBG="dark.main" />
            ) : (
              <NonActiveNavlink title={label} icon={icon} />
            )
          }
        </NavLink>
      ) : (
        <ExternalLink hyperLink={route} />
      )}
    </MenuItem>
  );
};

export default TreeItem;
