// ** React Imports
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

// ** Config
import { authConfig, getAuthToken, getKey } from 'src/constants/configs/auth';
import { clearStore } from 'src/redux/root_reducer';
// import { LOGIN_API_HANDLER } from 'src/redux/actions/loginActions/actions';
import urls from 'src/constants/configs/routApis';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { fetchWrapper } from 'src/Utils/fetchWrapper';

// ** Defaults
const defaultProvider = {
  user: null,
  loading: false,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized);
  const dispatch = useDispatch();

  // ** Hooks
  const navigate = useNavigate();

  const {mutate:handleLogin } = useMutation({
    mutationFn: async (params) => {
      setLoading(true);
      const response = await fetchWrapper('POST', urls.LOGIN, params)
      return response.data;
    },
    onSuccess: (response) => {
      window.localStorage.setItem(authConfig.storageTokenKeyName, response.access_token);
        window.localStorage.setItem(authConfig.sessionID, response[authConfig.sessionID]);
        const userInfo = jwtDecode(response.access_token);
        setUser(userInfo);
        setLoading(false);
        setIsInitialized(true);
        toast.success('Logged in successfully!');
        navigate('/', { replace: true });
    },
    onError: (error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message)
    },
    // retry: 2,
    // onSettled: (_, error,) => {
    //   if (error) {
    //     toast.error(error?.response?.data?.message)
    //   }
    // }
  })

  const handleLogout = () => {
    const sessionKey = localStorage.getItem(authConfig.sessionID);
    axios
      .post(
        urls.LOGOUT,
        { sessionKey },
        {
          headers: {
            [getKey()]: getAuthToken()
          }
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setUser(null);
          setIsInitialized(false);
          window.localStorage.removeItem(authConfig.storageTokenKeyName);
          window.localStorage.removeItem(authConfig.sessionID);
          dispatch(clearStore());
          navigate('/login');
          window.open(
            externalLinkGenerator('/v2_clear_localstorage.php', {
              key: sessionKey
            }),
            '_self'
          );
        }
      })
      .catch((error) => {
        toast.error('Failed to logout try again!');
      });
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
