import React, { useState, useEffect, Fragment } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

// icons
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import { V1Path, V1Url } from 'src/constants/configs';
import { useSessionKey } from 'src/hooks/useSessionKey';

const FLoatingBox = styled('div')(() => ({
  position: 'fixed',
  bottom: 20,
  right: 30
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  boxShadow: `${theme.customShadows.primary}, ${theme.shadows[10]}`,
  color: theme.palette.grey[100]
}));

const supportTeam = [
  { name: 'Hira Nawaz', phoneNo: process.env.REACT_APP_HIRA_NUMBER, id: 1 },
  {
    name: 'Rabia Maskeen',
    phoneNo: process.env.REACT_APP_RABIA_MASKEEN,
    id: 2
  },
  {
    name: 'Operation Manager',
    phoneNo: process.env.REACT_APP_OPERATION_MANAGER,
    id: 3
  }
];
const FloatingActions = () => {
  const [scrollVisible, setScrollVisible] = useState(false);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 100 ? setScrollVisible(true) : setScrollVisible(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);
  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <Fragment>
      <FLoatingBox>
        <Stack direction="row" sx={{ gap: 1 }}>
          <Tooltip title="Customer Support">
            <StyledIconButton
              onClick={handleOpen}
              sx={{
                bgcolor: 'primary.main',
                '&:hover': { bgcolor: 'dark.main' }
              }}
            >
              <SupportAgentOutlinedIcon />
            </StyledIconButton>
          </Tooltip>
          <Box
            sx={{
              opacity: scrollVisible ? 1 : 0,
              transition: 'opacity 0.3s ease',
              pointerEvents: scrollVisible ? 'auto' : 'none'
            }}
          >
            <Tooltip title="Scroll to top">
              <StyledIconButton
                sx={{
                  bgcolor: 'dark.main',
                  '&:hover': { bgcolor: 'primary.main' }
                }}
                onClick={handleScroll}
              >
                <KeyboardArrowUpOutlinedIcon />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Stack>
      </FLoatingBox>
      <CustomerSupport open={open} handleClose={handleClose} />
    </Fragment>
  );
};

export default FloatingActions;

function CustomerSupport({ open, handleClose }) {
  const sessionKey = useSessionKey();

  function handleClick(phoneNo) {
    window.open(`https://wa.me/${phoneNo}`);
    handleClose();
  }

  function handleOtherClicks(page) {
    const baseURL = V1Url;
    const path = `${V1Path}${page}`;
    const params = new URLSearchParams({ key: sessionKey });
    const url = new URL(path, baseURL);
    url.search = params.toString();
    window.open(url.toString(), '_blank');
    handleClose();
  }

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          width: 240,
          transform: 'translate(1000px)',
          boxShadow: 10
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 2,
          px: 2.5,
          bgcolor: 'primary.main'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" textAlign="center">
            Customer Support
          </Typography>
        </Box>
      </Box>
      {supportTeam.map((member, index) => {
        return (
          <Fragment key={member.id}>
            <MenuItem onClick={() => handleClick(member.phoneNo)}>
              <WhatsAppIcon sx={{ mr: 2, color: 'success.main' }} />
              {member.name}
            </MenuItem>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Fragment>
        );
      })}
      <MenuItem onClick={() => handleOtherClicks('/complaints_dashboard.php')}>
        <InfoOutlinedIcon sx={{ mr: 2, color: 'error.main' }} />
        Complaints Center
      </MenuItem>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <MenuItem onClick={() => handleOtherClicks('/suggestions_dashboard.php')}>
        <RecommendOutlinedIcon sx={{ mr: 2, color: 'info.main' }} />
        Suggestions ?
      </MenuItem>
    </Popover>
  );
}
