import { types } from 'src/redux/types';

const initialState = {
  CLASSES: {}
};

export const classesReducer = (state = initialState.CLASSES, action) => {
  if (types.CLASSES.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.CLASSES.FETCH_SUCCESS === action.type) {
    return action.payload;
  }
  if (types.CLASSES.FETCH_FAILURE === action.type) {
    return state;
  }
  return state;
};
