import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

// icons
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// custom imports
import Searchbar from 'src/layouts/components/searchbar';
import UserDropdown from 'src/layouts/components/userDropdown';
import { useAuth } from 'src/hooks/useAuth';
import useUser from 'src/hooks/useUser';
import { USERDATA } from 'src/constants/configs';
import { HEADER_DESKTOP, HEADER_MOBILE, NAV_WIDTH } from '../configs';
import NotificationsPopover from 'src/layouts/components/notification';
import SmsDropdown from 'src/layouts/components/smsDropdown';
import QuickLinksDropdown from 'src/layouts/components/quickLinksDropdown';

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.customShadows.z8,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH}px)`
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(1)
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 2)
  }
}));

Header.propTypes = {
  onOpenNav: PropTypes.func
};

export default function Header({ onOpenNav }) {
  const auth = useAuth();
  const USER = useUser();
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.secondary',
            display: { lg: 'none' }
          }}
        >
          <MenuOpenIcon />
        </IconButton>
        <Box
          sx={{
            flex: '1 1 50%',
            maxWidth: '50%',
            display: { xs: 'none', md: 'block' }
          }}
        >
          <Searchbar />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={{
            xs: 0.5,
            sm: 1
          }}
          sx={{ flex: '1 0.5 30%' }}
        >
          <QuickLinksDropdown />
          <NotificationsPopover />
          <SmsDropdown />

          <UserDropdown userData={USER[USERDATA]} logout={auth.logout} />
        </Stack>
      </StyledToolbar>
      <Box
        sx={{
          display: { md: 'none' },
          px: 2
        }}
      >
        <Searchbar />
      </Box>
    </StyledRoot>
  );
}
