import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// @mui
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// icons
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// custom
import { SMS_COUNT_API_HANDLER } from 'src/redux/actions/loginActions/actions';

const SmsDropdown = () => {
  const dispatch = useDispatch();
  const [smsCount, setSmsCount] = useState(null);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    const getSmsCount = async () => {
      const response = await dispatch(SMS_COUNT_API_HANDLER());
      if (response && response.status === 200) {
        setSmsCount(response.result);
      }
    };
    getSmsCount();
  }, [dispatch]);
  return (
    <Fragment>
      {smsCount ? (
        <Fragment>
          <Tooltip arrow title="SMS used / Total SMS">
            <Chip
              icon={<MailOutlineOutlinedIcon fontSize="small" />}
              color="primary"
              variant="outlined"
              label={`${smsCount.smsUsed} / ${smsCount.totalSms}`}
              sx={{ '& .MuiChip-label': { lineHeight: 'normal' } }}
              onClick={handleOpen}
            />
          </Tooltip>
          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                mt: 1.5,
                ml: 0.75,
                px: 2
              }
            }}
          >
            <List>
              <ListItem>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <TextsmsOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>Monthly Limit:</b> {smsCount.totalSms} SMS
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <TextsmsOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>SMS Used:</b> {smsCount.smsUsed} SMS
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon sx={{ color: 'primary.main' }}>
                  <TextsmsOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>SMS Left:</b> {smsCount.totalSms - smsCount.smsUsed} SMS
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon sx={{ color: 'success.main' }}>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>Whatsapp Limit:</b> {smsCount.whatsAppLimit} SMS
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon sx={{ color: 'success.main' }}>
                  <WhatsAppIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1">
                      <b>Whatsapp SMS Left:</b> {smsCount.whatsappLeft} SMS
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Popover>
        </Fragment>
      ) : (
        <Skeleton variant="rounded" width={100} height={30} sx={{ borderRadius: 99999 }} />
      )}
    </Fragment>
  );
};

export default SmsDropdown;
