// ** React Imports
import { createContext, useState } from 'react';

// ** Defaults
const defaultProvider = {
  showLayout: true,
  setShowLayout: () => {}
};

const LayoutContext = createContext(defaultProvider);

const LayoutProvider = ({ children }) => {
  const [showLayout, setShowLayout] = useState(defaultProvider.showLayout);

  const values = {
    showLayout: showLayout,
    setShowLayout: setShowLayout
  };

  return <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };
