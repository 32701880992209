import Box from '@mui/material/Box';
import EduPortalWhite from 'src/assets/Eduportal Logo-whiteWithoutTagline.svg';
import EduPortalDark from 'src/assets/Eduportal Logo-darkwithoutTagline.svg';
import EduPortalWhiteTagline from 'src/assets/Eduportal Logo-whiteWithTagline.svg';
import EduPortalDarkTagline from 'src/assets/Eduportal Logo-darkwithTagline.svg';

const EduPortalLogo = ({ size, mode, tagline }) => {
  const getLogo = () => {
    if (tagline) {
      if (mode === 'light') {
        return EduPortalWhiteTagline;
      }
      return EduPortalDarkTagline;
    }
    if (mode === 'light') {
      return EduPortalWhite;
    }
    return EduPortalDark;
  };
  const getSize = () => {
    if (size === 'small') return 150;
    if (size === 'large') return 350;
    return 250;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&>img': {
          width: getSize()
        }
      }}
    >
      <img src={getLogo()} alt="eduportal logo" />
    </Box>
  );
};

export default EduPortalLogo;
