export const types = {
  FEES: {
    FETCH_REQUEST: 'FEES_FETCH_REQUESTED',
    FETCH_SUCCESS: 'FEES_FETCH_SUCCESS',
    FETCH_FAILURE: 'FEES_FETCH_FAILURE'
  },
  SOCIALPOSTS: {
    FETCH_REQUEST: 'FEES_FETCH_REQUESTED',
    FETCH_SUCCESS: 'FEES_FETCH_SUCCESS',
    FETCH_FAILURE: 'FEES_FETCH_FAILURE'
  },
  LOGIN: {
    FETCH_REQUEST: 'FEES_FETCH_REQUESTED',
    FETCH_SUCCESS: 'FEES_FETCH_SUCCESS',
    FETCH_FAILURE: 'FEES_FETCH_FAILURE',
    STORE_USER: 'STORE_USER'
  },
  USERPRIVILEGES: {
    STORE_USER_PRIVILEGES: 'STORE_USER_PRIVILEGES',
    STORE_FLAT_PRIVILEGES: 'STORE_FLAT_PRIVILEGES'
  },
  STUEDENTS: {
    FETCH_REQUEST: 'STUDENTS_FETCH_REQUESTED',
    FETCH_SUCCESS: 'STUDENTS_FETCH_SUCCESS',
    FETCH_FAILURE: 'STUDENTS_FETCH_FAILURE',
    STORE_SORTED_STUDENTS: 'STORE_SORTED_STUDENTS',
    STORE_CUTOFF_TIME: 'STORE_CUTOFF_TIME'
  },
  CLASSES: {
    FETCH_REQUEST: 'CLASSES_FETCH_REQUESTED',
    FETCH_SUCCESS: 'CLASSES_FETCH_SUCCESS',
    FETCH_FAILURE: 'CLASSES_FETCH_FAILURE'
  },
  ATTENDANCE: {
    FETCH_SUCCESS: 'ATTENDANCE_FETCH_SUCCESS',
    FETCH_REQUEST: 'ATTENDANCE_FETCH_REQUESTED',
    FETCH_FAILURE: 'ATTENDANCE_FETCH_FAILURE',
    STORE_ATTENDANCE: 'STORE_ATTENDANCE'
  },
  SESSIONS: {
    FETCH_REQUEST: 'SESSIONS_FETCH_REQUESTED',
    FETCH_SUCCESS: 'SESSIONS_FETCH_SUCCESS',
    FETCH_FAILURE: 'SESSIONS_FETCH_FAILURE'
  },
  FEE_HEADS: {
    FETCH_REQUEST: 'FEE_HEADS_FETCH_REQUESTED',
    FETCH_SUCCESS: 'FEE_HEADS_FETCH_SUCCESS',
    FETCH_FAILURE: 'FEE_HEADS_FETCH_FAILURE'
  },
  FEE_SETTINGS: {
    FETCH_REQUEST: 'FEE_SETTINGS_FETCH_REQUESTED',
    FETCH_SUCCESS: 'FEE_SETTINGS_FETCH_SUCCESS',
    FETCH_FAILURE: 'FEE_SETTINGS_FETCH_FAILURE'
  }
};
