import { createContext, useState } from 'react';

// Defaults
const defaultProvider = {
  students: [],
  setStudents: () => null
};

const EditContext = createContext(defaultProvider);

function EditProvider({ children }) {
  const [students, setStudents] = useState(defaultProvider.students);

  const values = [students, setStudents];

  return <EditContext.Provider value={values}> {children}</EditContext.Provider>;
}

export { EditContext, EditProvider };
