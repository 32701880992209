import axios from 'axios';
import { toast } from 'react-hot-toast';

// custom imports
import { authConfig, getAuthToken, getKey } from 'src/constants/configs/auth';
// import { externalLinkGenerator } from './externalLink';

/**
 * @function getBearerToken
 * @description
 *   Token stored in Local Storage and return a string of Bearer Token
 */

/**
 * @function fetchWrapper
 * @param {string} arg1 - REST method | url
 * @param {string} [url] - url
 * @param {Object} [body] - body of message
 * @description
 *   Wrapper for the fetch api that provides options defaults and base response code handling.
 * @return {Promise<Object>} A promise containing the deserialized response object.
 */

const specificRoutes = ['/auth/login', '/challan/paymentdetails']
export const fetchWrapper = async (arg1, url, body, additionalOptions) => {
  try {
    // if called with one argument, default to 'GET' method
    const _method = url ? arg1.toUpperCase() : 'GET';
    // if called without method arg, the first
    const _url = url || arg1;
    const options = {
      method: _method,
      headers: {
        Accept: 'application/json',
        [getKey()]: getAuthToken(),
        'Content-Type': 'application/json'
      },
      data: body, // axios expects data instead of body
      ...additionalOptions
    };
    const response = await axios(`${_url}`, options);
    return response;
  } catch (error) {
    if (specificRoutes.some(route => url.includes(route))) {
      throw error;
    }
    const newError = handleError(error);
    return newError;
  }
};

/**
 * @function handleError
 * @param {Object} error - The response object.
 * @description
 *   A handler for the fetch response Object
 * @return {Promise<Object>} A promise containing the deserialized response object.
 */

export const handleError = (error) => {
  if (error?.response?.status === 401) {
    toast.error(error?.response?.data?.message);
    if (!window.localStorage.getItem(authConfig.storageTokenKeyName)) {
      handleLogout();
      return error;
    }
    handleLogout();
    toast.error(error?.response?.data?.message);
    return error;
  }
  const message = error?.response?.data?.message || "Server isn't available.";
  toast.error(message);
  throw new Error(error);
};

function handleLogout() {
  // const key = window.localStorage.getItem(authConfig.sessionID);
  window.localStorage.removeItem(authConfig.storageTokenKeyName);
  window.localStorage.removeItem(authConfig.sessionID);
  // window.open(
  // 	externalLinkGenerator('/v2_clear_localstorage.php', {
  // 		key,
  // 	}),
  // 	'_self'
  // );
}
