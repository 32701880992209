// react imports
import { useState, Fragment } from 'react';
import { AvatarGenerator } from 'random-avatar-generator';
import seedrandom from 'seedrandom';

// mui imports
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material';

// icons
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

// custom imports
import { useSessionKey } from 'src/hooks/useSessionKey';
import { externalLinkGenerator } from 'src/Utils/externalLink';

const generator = new AvatarGenerator();

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));

const UserDropdown = ({ userData, logout }) => {
  const { designationName, fullName, userId } = userData;
  const key = useSessionKey();
  // states
  const [anchorEl, setAnchorEl] = useState(null);

  // hooks
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = (url) => {
    if (url) {
      // router.push(url)
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  const handleProfileClick = () => {
    const url = externalLinkGenerator('/edit_user.php', {
      key
    });
    window.open(url, '_blank');
    handleDropdownClose();
  };

  // Function to generate the same avatar for a user ID
  const generateAvatarForUserId = (userId) => {
    const rng = seedrandom(userId);
    return generator.generateRandomAvatar(rng);
  };

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleDropdownOpen}
        sx={{
          p: 0.6,
          // pr: { xs: 0.6, md: 2 },
          py: 0.6,
          borderRadius: 99999,
          transition: 'all 200ms ease-in',
          backgroundColor: { xs: '', md: 'dark.main' },
          border: { xs: 0, md: '0.8px solid' },
          borderColor: 'grey.200',
          cursor: 'pointer'
        }}
      >
        {/* <Avatar
					alt={fullName}
					src={generateAvatarForUserId(userId)}
					sx={{
						width: { xs: 35, md: 30 },
						height: { xs: 35, md: 30 },
					}}
				/> */}
        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
          <Avatar
            alt={fullName}
            src={generateAvatarForUserId(userId)}
            sx={{
              width: { xs: 35, md: 30 },
              height: { xs: 35, md: 30 }
            }}
          />
        </StyledBadge>

        {/* <Stack
					sx={{
						ml: 0.5,
						display: { xs: 'none', md: 'flex' },
					}}
				>
					<Typography
						variant='body2'
						color='primary'
						fontWeight='bold'
						sx={{
							width: 120,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							lineHeight: 1,
						}}
					>
						{fullName}
					</Typography>

					<Typography
						variant='caption'
						sx={{
							fontSize: '10px',
							fontWeight: 'bold',
							color: 'grey.500',
							lineHeight: 1,
						}}
					>
						{designationName}
					</Typography>
				</Stack> */}
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        PaperProps={{
          style: { width: '150px' }
        }}
      >
        <Stack
          sx={{
            gap: 0.6,
            px: 2,
            py: 0.5,
            backgroundColor: 'grey.100'
            // display: { xs: 'block', md: 'none' },
          }}
        >
          <Typography variant="body2" color="primary.main" sx={{ lineHeight: 1 }} fontWeight="bold">
            {fullName}
          </Typography>
          <Typography variant="caption" color="grey.400" fontWeight="bold" sx={{ mt: -1 }}>
            {designationName}
          </Typography>
        </Stack>

        <MenuItem onClick={handleProfileClick}>
          <PersonOutlineOutlinedIcon
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary'
            }}
          />
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutOutlinedIcon
            sx={{
              marginRight: 2,
              fontSize: '1.375rem',
              color: 'text.secondary'
            }}
          />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
