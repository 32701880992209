import { types } from 'src/redux/types';

const initialState = {
  STUDENTS_RECORDS: {
    studentsData: [],
    sortedStudentsData: {},
    cutOffTime: 0
  }
};

export const studentReducer = (state = initialState.STUDENTS_RECORDS, action) => {
  if (types.STUEDENTS.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.STUEDENTS.FETCH_SUCCESS === action.type) {
    return { ...state, studentsData: action.payload };
  }
  if (types.STUEDENTS.FETCH_FAILURE === action.type) {
    return state;
  }
  if (types.STUEDENTS.STORE_SORTED_STUDENTS === action.type) {
    return { ...state, sortedStudentsData: action.payload };
  }
  if (types.STUEDENTS.STORE_CUTOFF_TIME === action.type) {
    return { ...state, cutOffTime: action.payload };
  }
  return state;
};
