import { combineReducers } from 'redux';
import { feesReducer } from '../reducer/feesReducer';
import { studentReducer } from '../reducer/studentReducer';
import { classesReducer } from '../reducer/classesReducer';
import { attendanceReducer } from '../reducer/attendanceReducer';
import { loginReducer } from '../reducer/loginReducer';
import { sessionsReducer } from '../reducer/sessionReducer';
import { feeHeadsReducer } from '../reducer/feeHeadsReducer';
import { feesSettingsReducer } from '../reducer/feeSettingsReducer';

const rootReducer = combineReducers({
  FEES: feesReducer,
  STUDENTS: studentReducer,
  CLASSES: classesReducer,
  ATTENDANCE: attendanceReducer,
  USER: loginReducer,
  SESSIONS: sessionsReducer,
  FEE_HEADS: feeHeadsReducer,
  FEE_SETTINGS: feesSettingsReducer
});
export const CLEAR_STORE = 'CLEAR_STORE';
export const clearStore = () => ({ type: CLEAR_STORE });

const clearCombinedReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default clearCombinedReducer;
