import { types } from 'src/redux/types';

const initialState = {
  FEE_SETTINGS: []
};

export const feesSettingsReducer = (state = initialState.FEE_SETTINGS, action) => {
  if (types.FEE_SETTINGS.FETCH_REQUEST === action.type) {
    return state;
  }
  if (types.FEE_SETTINGS.FETCH_SUCCESS === action.type) {
    return action.payload;
  }
  if (types.FEE_SETTINGS.FETCH_FAILURE === action.type) {
    return state;
  }
  return state;
};
