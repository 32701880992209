import { useEffect, useState } from 'react';
import { authConfig } from 'src/constants/configs/auth';

export const useSessionKey = () => {
  const [sessionKey, setSessionKey] = useState('');

  useEffect(() => {
    setSessionKey(window.localStorage.getItem(authConfig.sessionID));
  }, []);

  return sessionKey;
};
