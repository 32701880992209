export function flatPrivileges(array) {
  if (array) {
    const allRoutes = array.map((privilege) => {
      const subRoutes = privilege.children.map((subb) => subb.route);
      return [privilege.route, ...subRoutes];
    });
    return allRoutes?.flat();
  }
  return [];
}
