import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';

//
import Header from './header';
import Nav from './nav';
import FloatingActions from '../components/floatingActions';
import { useLayout } from 'src/hooks/useLayout';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const Main = styled('div')(() => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    margin: '0 auto'
}));

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    const { showLayout } = useLayout();
    return (
        <StyledRoot>
            {showLayout && (
                <>
                    <Header onOpenNav={() => setOpen(true)} />
                    <Nav openNav={open} onCloseNav={() => setOpen(false)} />
                </>
            )}

            <Main
                sx={{
                    paddingTop: showLayout ? { xs: `${APP_BAR_MOBILE + 70}px`, md: `${APP_BAR_DESKTOP + 30}px`, ld: `${APP_BAR_DESKTOP + 8}px` } : '10px',
                    px: showLayout ? 2 : '10px',
                    pb: showLayout ? 10 : '10px',
                    maxWidth: showLayout ? '1440px' : 'auto'
                }}
            >
                <Outlet />
                {showLayout && <FloatingActions />}
            </Main>
        </StyledRoot>
    );
}
