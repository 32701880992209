import PropTypes from 'prop-types';
// import { set, sub } from 'date-fns';
import { useState, Fragment, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// utils
import { fToNow } from 'src/Utils/formatTime';
// icons
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// assets
import { Notifications_API_HANDLER } from 'src/redux/actions/loginActions/actions';
import { externalLinkGenerator } from 'src/Utils/externalLink';
import { useSessionKey } from 'src/hooks/useSessionKey';
import toast from 'react-hot-toast';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const key = useSessionKey();
  const dispatch = useDispatch();

  const totalUnRead = useMemo(() => {
    return notifications.reduce((acc, item) => {
      if (!item.isRead) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [notifications]);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await dispatch(Notifications_API_HANDLER());
        setNotifications(data?.result);
        setIsloading(false);
      } catch (error) {
        toast.error(error.message);
      }
    })();
  }, [dispatch]);

  function handleViewAll() {
    const url = externalLinkGenerator('/push_notifications.php', { key });
    window.open(url.toString(), '_blank');
    handleClose();
  }

  return (
    <Fragment>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          {open ? <NotificationsIcon /> : <NotificationsOutlinedIcon />}
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360
          }
        }}
      >
        <Box bgcolor="dark.main" sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" color="grey.0">
              Notifications
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {isLoading ? (
          [1, 2, 3, 4, 5, 6].map((item) => {
            return <LoadingItem key={item} />;
          })
        ) : (
          <Box sx={{ maxHeight: 340, overflow: 'auto' }}>
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              {notifications.map((notification) => (
                <NotificationItem key={notification.notif_id} notification={notification} handleClose={handleClose} />
              ))}
            </List>
          </Box>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={handleViewAll}>
            View All
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    posted_date: PropTypes.string,
    notif_id: PropTypes.number,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

function NotificationItem({ notification, handleClose }) {
  const { title } = renderContent(notification);
  const key = useSessionKey();

  function handleNotificationClick(id) {
    const url = externalLinkGenerator('/push_notifications.php', { key, id });
    window.open(url.toString(), '_blank');
    handleClose();
  }

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead && {
          bgcolor: 'action.selected'
        })
      }}
      onClick={() => handleNotificationClick(notification.notif_id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', color: 'black' }}>
          <CampaignOutlinedIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.posted_date)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      {/* <Typography
				component='span'
				variant='body2'
				sx={{ color: 'text.secondary' }}
			>
				&nbsp; {notification.description}
			</Typography> */}
    </Typography>
  );

  return {
    title
  };
}

function LoadingItem() {
  return (
    <Stack sx={{ p: 1 }}>
      <Stack direction="row" sx={{ gap: 1 }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" sx={{ fontSize: '0.8rem', width: 230 }} />
      </Stack>
    </Stack>
  );
}
