export const BASE_URL = getBaseURL("API's");
export const SOCKET_URL = getBaseURL('SOCKETS');
export const V1Url = `${process.env.REACT_APP_V1BASEURL}`;
export const V1Path = `${process.env.REACT_APP_V1PATH}`;
export const imageBaseURL = process.env.REACT_APP_IMAGE_BASE_URL;
export const signatureBaseURL = process.env.REACT_APP_SIGNATURE_BASE_URL;
export const studentImageBaseURL = process.env.REACT_APP_STUDENT_PICTURE;
// dashboard sockets
export const fixedAnalytics = 'fixedAnalytics';
export const graphData = 'graphData';
export const dailyCollection = 'dailyCollection';
export const feeReceivable = 'feeReceivable';
export const feeReceived = 'feeReceived';
export const expectedCollection = 'expectedCollection';

// data set for the token and priveleges store in redux
export const PRIVILEGES = 'PRIVILEGES';
export const USERDATA = 'USERDATA';

function getBaseURL(service = "API's") {
  // API's / SOCKETS
  if (service === "API's") {
    // if (process.env.REACT_APP_ENVIROMENT === 'DEVELOP') {
    //   return `${process.env.REACT_APP_DEV_BASE_URL}:${process.env.REACT_APP_DEV_NODE_PORT}`;
    // }
    if (process.env.REACT_APP_ENVIROMENT === 'DEVELOP') {
      return `${process.env.REACT_APP_DEV_BASE_URL}`;
    }
    if (process.env.REACT_APP_ENVIROMENT === 'PRODUCTION') {
      return `${process.env.REACT_APP_PROD_BASE_URL}`;
    }
    return `http://localhost:${process.env.REACT_APP_DEV_NODE_PORT}`;
  }
  // if (process.env.REACT_APP_ENVIROMENT === 'DEVELOP') {
  //   return `${process.env.REACT_APP_DEV_SOCKET_BASE_URL}:${process.env.REACT_APP_DEV_SOCKET_PORT}`;
  // }
  if (process.env.REACT_APP_ENVIROMENT === 'DEVELOP') {
    return `${process.env.REACT_APP_DEV_SOCKET_BASE_URL}`;
  }
  if (process.env.REACT_APP_ENVIROMENT === 'PRODUCTION') {
    return `${process.env.REACT_APP_PROD_SOCKET_BASE_URL}`;
  }
  return `http://localhost:${process.env.REACT_APP_DEV_SOCKET_PORT}`;
}

export const qrSecretSalt = process.env.REACT_APP_QR_SECRET;
